import React from 'react';
  
  const Insights = () =>  {
	return (
	  <div>
	  </div>
	);
  }
  
  export default Insights;
  